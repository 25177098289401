export class AboutUsSteps {
  private container: HTMLElement
  private activeView: number
  private wheelHandled: boolean
  private views: HTMLElement[]

  private readonly HIDDEN_CLASSNAME = 'js-scroll-view_hidden'

  constructor(container: HTMLElement) {
    this.container = container
    this.activeView = 0
    this.wheelHandled = false
    this.views = Array.from(container.querySelectorAll('.js-scroll-view'))

    const viewsToHide = container.querySelectorAll('.js-scroll-view:not(:first-of-type)')

    viewsToHide.forEach((view: HTMLElement) => {
      view.style.display = 'none'
      view.classList.add(this.HIDDEN_CLASSNAME)
    })
  }

  public startScrollListening() {
    const handleWheel = () => {
      if (this.wheelHandled)
        return

      this.wheelHandled = true

      this.container.onwheel = (e) => {
        if (e.deltaY > 0) {
          if (this.activeView !== this.views.length - 1) {
            this.activeView++
            
            this.switchToActiveView()
          }
        }

        else {
          if (this.activeView > 0) {
            this.activeView--
            this.switchToActiveView()
          }
        }

        this.container.onwheel = null
        this.wheelHandled = false
      }
    }

    setInterval(handleWheel, 1000)
  }

  private switchToActiveView() {
    const newView = this.views[this.activeView]
    const oldViews = this.container.querySelectorAll(`.js-scroll-view:not(:nth-of-type(${this.activeView + 1}))`) /* нумерация в css начинается с 1 */

    oldViews.forEach((oldView: HTMLElement) => {
      oldView.classList.add(this.HIDDEN_CLASSNAME)

      setTimeout(() => {
        oldView.style.display = 'none'

        newView.style.display = 'block'

        setTimeout(() => {
          newView.classList.remove(this.HIDDEN_CLASSNAME)
        }, 250);
      }, 250);
    })


  }
}