import { sectionType } from "./ProjectsBoard";

export class ProjectsBoardPagination {
  private slides: HTMLElement[]

  public activeSection: sectionType = 'web'

  private element: HTMLElement

  constructor(selector: string) {
    this.element = document.querySelector(selector)

    this.slides = Array.from(this.element.querySelectorAll('div[data-pag-section]'))

    this.slides.forEach(slide => {
      const slideSection = slide.getAttribute('data-pag-section') as sectionType

      slide.addEventListener('click', () => {
        this.setActiveSection(slideSection)
      })
    })

    this.setActiveSection(this.activeSection)

    //document.addEventListener('scroll', () => this.controllVisibilityOnMobile())
  }

  public hide() {
    this.element.classList.add('projects-board__pagination_hidden', 'd-none')
  }

  public onSectionChange(newSection: sectionType) { } /* set in controller */

  public show() {
    this.element.classList.remove('d-none')

    setTimeout(() => {
      this.element.classList.remove('projects-board__pagination_hidden')
    }, 1000);
  }

  private setActiveSection(newActiveSection: sectionType) {
    const applyStyles = (oldSection: sectionType, newSection: sectionType) => {
      const activeSectionClassName = 'section-name_active'

      const oldActiveSection = this.element.querySelector(`div[data-pag-section="${oldSection}"]`)
      const newActiveSection = this.element.querySelector(`div[data-pag-section="${newSection}"]`)

      oldActiveSection.classList.remove(activeSectionClassName)
      newActiveSection.classList.add(activeSectionClassName)
    }

    applyStyles(this.activeSection, newActiveSection)

    this.activeSection = newActiveSection
    this.onSectionChange(newActiveSection)
  }

  public onBoardEnterViewport () {
    this.element.classList.remove('mobile-d-none')
  }

  public onBoardExitViewport () {
    this.element.classList.add('mobile-d-none')
  }
}