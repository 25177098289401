export class MobileHeader {
    constructor() {
        const firstSection = document.querySelector('section:first-of-type')
        this.firstSectionHeight = firstSection.getBoundingClientRect().height

        this.headerElement = document.getElementById('rootlet')

        this.toggleVisibilityOnScroll()

        this.menuIsOpened = false

        document.addEventListener('scroll', () => this.toggleVisibilityOnScroll())
    }

    private toggleVisibilityOnScroll() {
        if (this.menuIsOpened) {
            this.headerElement.classList.add(this.openedClass)
            
            return
        }

        if (window.scrollY > this.firstSectionHeight) {
            this.headerElement.classList.remove(this.hiddenClass)
            this.headerElement.classList.add(this.openedClass)
        }

        else {
            this.headerElement.classList.add(this.hiddenClass)
            this.headerElement.classList.remove(this.openedClass)
        }
    }

    public onMenuToggle() {
        this.menuIsOpened = !this.menuIsOpened
        this.headerElement.classList.toggle(this.transparentClass)
    }

    public onMenuHide() {
        this.headerElement.classList.remove(this.transparentClass)
        this.menuIsOpened = false
    }

    private firstSectionHeight: number

    private headerElement: HTMLElement

    private hiddenClass = 'rootlet_mobile-hidden'
    private openedClass = 'rootlet_mobile-visible'
    private transparentClass = 'rootlet_transparent'
    private firstAppearence = true

    public menuIsOpened: boolean
}