import Swiper from "swiper";

export type sectionType = 'mobile' | 'web' | 'programming' /* enum */

export class ProjectsBoard {
  constructor(containerSelector: string) {
    this.swiper = new Swiper('[data-role="board-swiper"]', {
      allowTouchMove: false
    })

    this.activeSection = "web"
    this.setActiveBoard(this.activeSection)

    this.projectsBoard = document.querySelector(`${containerSelector} div[data-role="board"]`)
  }

  private projectsBoard: HTMLElement
  private activeSection: sectionType
  private swiper: Swiper

  public openBoard() {
    this.projectsBoard.style.maxHeight = `${this.boardHeight}px`

    this.projectsBoard.classList.add('projects-board__main_opened')
    this.swiper.wrapperEl.classList.add('js-could-scroll')
  }

  public closeBoard() {
    this.projectsBoard.style.maxHeight = '0'

    this.projectsBoard.classList.remove('projects-board__main_opened')

    this.swiper.wrapperEl.classList.remove('js-could-scroll')
  }

  public setActiveBoard(section: sectionType) {
    const getNewSlideIndex = () => {

      const idxToFind = Array.from(this.swiper.slides).findIndex(slide => {
        const slideElem = slide as HTMLElement
        const slideSection = slideElem.dataset.section

        return (slideSection == section)
      })

      return idxToFind
    }

    this.swiper.slideTo(getNewSlideIndex())
    this.activeSection = section
    this.projectsBoard = document.querySelector(`div[data-section=${this.activeSection}] div[data-role="board"]`)
  }

  private get boardHeight() {
    const windowWidth = window.innerWidth

    const cardsAreInColumn = windowWidth < 992 /* если ширина окна меньше 992, то карточки выводятся колонкой а не сеткой */

    const projectCardsAmount = this.projectsBoard.querySelectorAll('.project-card').length
    const slideHeight = this.projectsBoard.querySelector('.project-card').clientHeight

    const boardHeight = projectCardsAmount * slideHeight

    if (cardsAreInColumn) {
      if (projectCardsAmount % 2 == 0)
        return boardHeight * 2
      
      else 
        return (boardHeight + 1) * 2
    }

    return boardHeight
  }
}