import { PointerSource } from '../scripts/PointerSource'
import { autorun } from 'mobx'

export function initBackgroundPointer(pointer: PointerSource) {
  console.log("!");
  
  const elem = document.getElementById('js-background-pointer')
  elem.classList.remove('u-hidden')

  const delta = -elem.clientHeight / 2

  return autorun(() => {
    elem.style.transform = `translate(${delta + pointer.x}px, ${delta + pointer.y}px)`
  })
}
