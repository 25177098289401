export class QuestionsBoard {
  init() {
    const questionCards = document.querySelectorAll('.question')

    questionCards.forEach((qCard: HTMLElement) => {
      qCard.addEventListener('click', (e) => {
        if (!qCard.classList.contains(this.OPENED_CLASSNAME)) {

          document.querySelectorAll(`.${this.OPENED_CLASSNAME}`).forEach(el => el.classList.remove(this.OPENED_CLASSNAME))
          document.querySelectorAll(`.${this.OPENED_TO_TOP_CLASSNAME}`).forEach(el => el.classList.remove(this.OPENED_TO_TOP_CLASSNAME))
          document.querySelectorAll(`.${this.HIDDEN_CLASSNAME}`).forEach(el => el.classList.remove(this.HIDDEN_CLASSNAME))

          const nextQCard = qCard.nextSibling as HTMLElement

          if (nextQCard) {
            nextQCard.classList.add(this.HIDDEN_CLASSNAME)
          }

          else {

            if (window.innerWidth > 992) { /* on desktop some cards open to top, so we had to hide prev card */
              const prevQCard = qCard.previousSibling as HTMLElement

              prevQCard.classList.add(this.HIDDEN_CLASSNAME)

            }
            this.closeFirstCardInNextColumnIfExists(qCard)
          }

          const additionalClasses = nextQCard ? [this.OPENED_CLASSNAME] : [this.OPENED_CLASSNAME, this.OPENED_TO_TOP_CLASSNAME]

          qCard.classList.add(...additionalClasses)
        }
        else {
          document.querySelectorAll(`.${this.HIDDEN_CLASSNAME}`).forEach(el => el.classList.remove(this.HIDDEN_CLASSNAME))

          qCard.classList.remove(...[this.OPENED_CLASSNAME, this.OPENED_TO_TOP_CLASSNAME])
        }
      })
    })
  }

  private closeFirstCardInNextColumnIfExists(qCard: HTMLElement) {
    if (window.innerWidth > 992)
      return
    const nextQCol = qCard.parentNode.nextSibling

    if (nextQCol) {
      const firstQCardInNextCol = Array.from(nextQCol.childNodes)[0] as HTMLElement

      if (firstQCardInNextCol) {
        firstQCardInNextCol.classList.add(this.HIDDEN_CLASSNAME)
      }
    }
  }

  private readonly HIDDEN_CLASSNAME = 'question_hidden'
  private readonly OPENED_CLASSNAME = 'question_expanded'
  private readonly OPENED_TO_TOP_CLASSNAME = 'question_expanded_to-top'
}