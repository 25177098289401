import { autorun, makeAutoObservable } from 'mobx'
import { MobileHeader } from '../scripts/MobileHeader'
import { Slides } from '../scripts/Slides'

export class Menu {
  private menuBtn: HTMLElement
  private modal: HTMLElement
  private contentBlock: HTMLElement
  private images: HTMLImageElement[]
  private links: HTMLAnchorElement[]
  private header: MobileHeader

  private hoveredImageId: null | string = null

  private slides: Slides

  constructor({ slides }: { slides: Slides }, header: MobileHeader) {
    makeAutoObservable(this)
    this.slides = slides

    this.modal = document.getElementById('js-menu-modal')
    this.menuBtn = document.getElementById('js-menu-btn')
    this.contentBlock = document.getElementById('scroll-container')
    this.images = Array.from(this.modal.querySelectorAll('.images > picture > img'))
    this.links = Array.from(this.modal.querySelectorAll('nav a'))

    this.header = header
  }

  private get activeImage(): string {
    return this.hoveredImageId ?? this.activeSection
  }

  private get activeSection(): string {
    return this.slides.activeSection.name ?? 'main'
  }

  init() {
    this.menuBtn.addEventListener('click', this.toggle)

    this.modal.addEventListener('click', (event) => {
      if ((event.target as HTMLElement).tagName == 'A') this.hide()
    })

    this.modal.addEventListener('pointerenter', this.changeCurrent)
    this.modal.addEventListener('mouseover', this.changeCurrent)

    this.modal.addEventListener('pointerleave', this.inactive)
    this.modal.addEventListener('mouseout', this.inactive)

    autorun(() => {
      this.images.forEach((img) => {
        if (img.dataset.section === this.activeImage) img.classList.add('-active')
        else img.classList.remove('-active')
      })
    })

    autorun(() => {
      this.links.forEach((link) => {
        if (link.href.includes(`#${this.activeSection}`)) link.classList.add('-active')
        else link.classList.remove('-active')
      })
    })
  }

  private hide = () => {
    this.modal.classList.remove('-open')
    this.menuBtn.classList.remove('-cross')
    this.contentBlock.classList.remove('u-hidden')

    document.body.style.overflow = 'visible'

    this.header.onMenuHide()
  }

  private toggle = () => {
    const bodyOverflow = getComputedStyle(document.body).overflow

    if (bodyOverflow == 'visible')
      document.body.style.overflow = 'hidden'
    else
      document.body.style.overflow = 'visible'

    this.menuBtn.classList.toggle('-cross')
    this.modal.classList.toggle('-open')
    this.contentBlock.classList.toggle('u-hidden')


    this.header.onMenuToggle()
  }

  private changeCurrent = ({ target: srcTarget }: { target: EventTarget }) => {
    const target = srcTarget as HTMLAnchorElement
    if (target.tagName == 'A' && target.classList.contains('item')) {
      this.hoveredImageId = target.dataset.section ?? target.href.replace(/^.+#(.+)$/, '$1')
      this.header.onMenuHide()
    }
  }

  private inactive = ({ target: srcTarget }: { target: EventTarget }) => {
    const target = srcTarget as HTMLAnchorElement
    if (target.tagName == 'A' && target.classList.contains('item')) {
      this.hoveredImageId = null
    }
  }
}
