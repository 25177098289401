export function inRange(a, min, max) {
  return Math.min(max, Math.max(a, min))
}

export function elementOrParentsScrollable(el: Element) {
  let shouldInterrupt = false
  while (el && !shouldInterrupt && el !== document.body) {
    const canScroll = el.classList.contains('js-could-scroll')

    if (canScroll) {
      shouldInterrupt = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight
    }

    el = el.parentElement
  }
  return shouldInterrupt
}