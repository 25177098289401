import { getPortfolioProjects } from "./portfolioProjects"

export class CurrentProjectInfo {
  constructor() {
    this.container = document.querySelector('#project-info-container') /* container to arg */
    this.closeButton = this.container.querySelector('.close-button')

    this.projects = Array.from(document.querySelectorAll('.project-card'))
  }

  init = async () => {
    const projectPages = await getPortfolioProjects()

    this.projects.forEach(project => {
      const projectName = project.getAttribute('data-project-name')

      this.closeButton.addEventListener('click', () => {
        this.closeInfo()
      })

      project.addEventListener('click', () => {
        this.displayInfo(projectPages[projectName])

        this.scrollPositionWhenOpened = window.scrollY

        const anchor = document.querySelector('.project-template__imgs')

        anchor.scrollIntoView()
      })
    })
  }

  private container: HTMLElement = null
  private projects: HTMLElement[] = []
  private closeButton: HTMLElement = null
  private scrollPositionWhenOpened: number

  public displayInfo(htmlString: string) {
    const previewElement = document.querySelector('.preview') as HTMLElement

    previewElement.style.opacity = '0';
    previewElement.style.display = 'none';

    this.container.classList.add('visible')

    this.container.querySelector('.project').innerHTML = htmlString

  }

  private closeInfo() {
    this.container.classList.remove('visible')
    const previewElement = document.querySelector('.preview') as HTMLElement

    previewElement.style.opacity = '1';
    previewElement.style.display = 'block';

    if (this.scrollPositionWhenOpened)
      window.scrollTo({ top: this.scrollPositionWhenOpened })
  }
}