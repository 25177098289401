import { PointerSource } from '../scripts/PointerSource'
import { autorun } from 'mobx'

export function bindPositionWatcher(pointer: PointerSource, elements: NodeListOf<HTMLDivElement>) {
  const elms = Array.from(elements)
  const XElm = elms.find((elm) => elm.dataset.l.toLowerCase().includes('x'))
  const YElm = elms.find((elm) => elm.dataset.l.toLowerCase().includes('y'))

  autorun(() => showPositionIn(pointer.x, XElm))
  autorun(() => showPositionIn(pointer.y, YElm))
}

function showPositionIn(pos: number, elm: HTMLDivElement) {
  elm.innerText = pos <= 70 && pos >= 68 ? 'NICE' : pos.toFixed(0)
}
