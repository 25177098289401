import { ProjectsBoard } from "./ProjectsBoard";
import { ProjectsBoardPagination } from "./ProjectsBoardPagination";

export class PortfolioController {
    private board: ProjectsBoard
    private pagination: ProjectsBoardPagination
    private closingButton: HTMLElement
    private openingButton: HTMLElement
    private container: HTMLElement

    constructor(board: ProjectsBoard, pagination: ProjectsBoardPagination, container: HTMLElement) {
        this.board = board
        this.pagination = pagination
        this.container = container
        
        this.pagination.onSectionChange = (newSection) => this.board.setActiveBoard(newSection)

        this.closingButton = container.querySelector('div[data-role="board-closer"]')
        this.openingButton = container.querySelector('div[data-role="board-opener"]')

        this.openingButton.addEventListener('click', () => this.handleBoardOpening())
        this.closingButton.addEventListener('click', () => this.handleBoardClosing())

        this.updateCoefficients()
        document.addEventListener('resize', () => this.updateCoefficients())

        document.addEventListener('scroll', () => this.controllPaginationVisibilityOnMobile())
    }

    private handleBoardOpening() {
        this.board.openBoard()
        this.pagination.hide()

        this.container.classList.add('board-opened')

        this.closingButton.classList.remove('d-none')
        this.openingButton.classList.add('portfolio__open-button_hidden', 'd-none')

        setTimeout(() => {
            this.closingButton.classList.remove('close-button_hidden')
        }, 250)
    }

    private handleBoardClosing() {
        this.board.closeBoard()
        this.pagination.show()

        
        this.closingButton.classList.add('close-button_hidden')
        
        setTimeout(() => {
            this.container.classList.remove('board-opened')
            this.openingButton.classList.remove('d-none', 'portfolio__open-button_hidden')

            this.closingButton.classList.add('d-none')
        }, 1000) /* on transition end */
    }

    private controllPaginationVisibilityOnMobile() {
        const boardEnteredViewport = (this.container.getBoundingClientRect().top - this.SHOW_EARLIER_BY) < 0
        const boardLeftViewport = this.container.getBoundingClientRect().bottom - this.HIDE_EARLIER_BY < 0

        if (boardEnteredViewport && !boardLeftViewport)
            this.pagination.onBoardEnterViewport()

        else 
            this.pagination.onBoardExitViewport()
    }

    private updateCoefficients() {
        this.HIDE_EARLIER_BY = window.innerHeight / 2
        this.SHOW_EARLIER_BY = window.innerHeight / 3
    }

    private SHOW_EARLIER_BY: number  /* уменьшить если нужно, чтобы пагинация появлялась раньше */
    private HIDE_EARLIER_BY: number  /* уменьшить, если нужно, чтобы пагинация исчезала позже */

}