import { Slides } from './Slides'
import { InputController } from './InputController'
import { PointerSource } from './PointerSource'
import { Menu } from '../feature/menu'
import { HistoryController } from './HistoryController'
import { initBackgroundPointer } from '../feature/animated-bg'
import { initVirtualLinks } from './virtualLinks'
import { bindPositionWatcher } from '../feature/cur-pos'
import { QuestionsBoard } from './Questions'
import { ProjectsBoard } from './ProjectsBoard'
import { elementOrParentsScrollable } from './utils'
import { CurrentProjectInfo } from './CurrentProjectInfo'
import { AboutUsSteps } from './AboutUsSteps'
import { ProjectsBoardPagination } from './ProjectsBoardPagination'
import { PortfolioController } from './PortfolioController'
import { MobileHeader } from './MobileHeader'

const main = () => {
  const container = document.getElementById('scroll-container')
  const slides = new Slides(container)
  new HistoryController(slides)
  // анимируем после перехода на вью из истории
  slides.init()

  new InputController(slides)

  const handleScroll = (e: Event) => {
    if (!elementOrParentsScrollable(e.target as Element)) {
      ; (e.target as HTMLElement).scrollLeft = 0
      return false
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 992) {
      slides.enabled = false
    }

    else {
      slides.enabled = true
    }
  }

  container.addEventListener(
    'scroll',
    handleScroll,
    { capture: true }
  )

  handleResize()

  window.onresize = handleResize

  const pointerSource = new PointerSource()

  const mobileHeader = new MobileHeader()

  const menu = new Menu({ slides }, mobileHeader)
  menu.init()

  Array.from(document.querySelectorAll('.section-header')).forEach((elm: HTMLAnchorElement) => {
    elm.addEventListener('click', () => {
      location.replace(elm.dataset.href)
    })
  })

  const virginInputs = Array.from(document.querySelectorAll('.transparent-input.-virgin'))
  virginInputs.forEach((input) =>
    input.addEventListener('blur', (e) => (e.target as HTMLElement).classList.remove('-virgin'), { once: true })
  )

  const textarea = document.querySelector('textarea.transparent-input') as HTMLTextAreaElement
  autoResizeTextarea(textarea)

  initBackgroundPointer(pointerSource)

  const virtualLinks: HTMLElement[] = Array.from(document.querySelectorAll('.js-link[data-href]'))
  initVirtualLinks(virtualLinks)

  bindPositionWatcher(pointerSource, document.querySelectorAll('.js-pos-watcher'))

  const quesitonBoard = new QuestionsBoard()

  quesitonBoard.init()

  new CurrentProjectInfo().init()

  new AboutUsSteps(document.querySelector('.work-stages')).startScrollListening()

  initPortfolio()
}

const initPortfolio = () => {
  const projectsBoard = new ProjectsBoard('#portfolio')
  const pagination = new ProjectsBoardPagination('div[data-role="board-pagination"]')

  new PortfolioController(projectsBoard, pagination, document.querySelector('#portfolio'))
}

function autoResizeTextarea(elem: HTMLTextAreaElement) {
  const resize = () => {
    elem.style.height = 'auto'
    elem.style.height = elem.scrollHeight + 'px'
  }

  elem.addEventListener('input', resize)
}

main()
