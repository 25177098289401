import { reaction } from 'mobx'
import { Slides } from './Slides'

export class HistoryController {
  slides: Slides

  constructor(slides: Slides) {
    this.slides = slides
    window.addEventListener('popstate', this.stateListener)

    reaction(
      () => slides.currentSlideId,
      (a) => {
        if (slides.enabled)
          window.history.pushState(null, '', '#' + a)
      }
    )

    this.stateListener()
  }

  stateListener = () => {
    if (window.location.pathname === '/' && window.location.hash) {
      this.slides.goTo(window.location.hash.slice(1))
    }
  }
}
