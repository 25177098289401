const botTalkProject = import('../viewports/projectPages/html/botTalk.html').then(res => res.default)
const slimiProject = import('../viewports/projectPages/html/slimi.html').then(res => res.default)
const smartlifeProject = import('../viewports/projectPages/html/smartlife.html').then(res => res.default)
const dveriProject = import('../viewports/projectPages/html/dveri.html').then(res => res.default)
const scandiProject = import('../viewports/projectPages/html/scandi.html').then(res => res.default)
const nmcProject = import('../viewports/projectPages/html/nmc.html').then(res => res.default)
const protectimusProject = import('../viewports/projectPages/html/protectimus.html').then(res => res.default)
const vmProject = import('../viewports/projectPages/html/vm.html').then(res => res.default)
const movewiseProject = import('../viewports/projectPages/html/movewise.html').then(res => res.default)
const grazdProject = import('../viewports/projectPages/html/grazd.html').then(res => res.default)
const partnerProject = import('../viewports/projectPages/html/partner.html').then(res => res.default)
const logosProject = import('../viewports/projectPages/html/logos.html').then(res => res.default)
//const vodoleyProject = import('../viewports/projectPages/html/vodoley.html').then(res => res.default)
const beautyProject = import('../viewports/projectPages/html/beauty.html').then(res => res.default)
const kvizProject = import('../viewports/projectPages/html/kviz.html').then(res => res.default)
const linkbridgeProject = import('../viewports/projectPages/html/linkbridge.html').then(res => res.default)
//const lukProject = import('../viewports/projectPages/html/luk.html').then(res => res.default)
const netProject = import('../viewports/projectPages/html/net.html').then(res => res.default)
const oldProject = import('../viewports/projectPages/html/old.html').then(res => res.default)
const attacheProject = import('../viewports/projectPages/html/attache.html').then(res => res.default)
const libraryProject = import('../viewports/projectPages/html/library.html').then(res => res.default)
const mealProject = import('../viewports/projectPages/html/meal.html').then(res => res.default)
//const metrovagonProject = import('../viewports/projectPages/html/metrovagon.html').then(res => res.default)
const predictAgeProject = import('../viewports/projectPages/html/predictAge.html').then(res => res.default)
const predictBuyProject = import('../viewports/projectPages/html/predictBuy.html').then(res => res.default)
const yogaProject = import('../viewports/projectPages/html/yoga.html').then(res => res.default)
const besedProject = import('../viewports/projectPages/html/besed.html').then(res => res.default)
const gazpromProject = import('../viewports/projectPages/html/gazprom.html').then(res => res.default)
const consultyProject = import('../viewports/projectPages/html/consulty.html').then(res => res.default)
const gamevaluesProject = import('../viewports/projectPages/html/gamevalues.html').then(res => res.default)
const skAcadProject = import('../viewports/projectPages/html/skAcad.html').then(res => res.default)

export const getPortfolioProjects = async () => ({
  'botTalk': await botTalkProject,
  'slimi': await slimiProject,
  'dveri': await dveriProject,
  'smartlife': await smartlifeProject,
  'scandi': await scandiProject,
  'nmc': await nmcProject,
  'protectimus': await protectimusProject,
  'vm': await vmProject,
  'movewise': await movewiseProject,
  'grazd': await grazdProject,
  'partner': await partnerProject,
  'logos': await logosProject,
  //'vodoley': await vodoleyProject,
  'beauty': await beautyProject,
  'kviz': await kvizProject,
  'linkbridge': await linkbridgeProject,
  //'luk': await lukProject,
  'net': await netProject,
  'attache': await attacheProject,
  'old': await oldProject,
  'library': await libraryProject,
  'meal': await mealProject,
  //'metrovagon': await metrovagonProject,
  'predictAge': await predictAgeProject,
  'predictBuy': await predictBuyProject,
  'yoga': await yogaProject,
  'besed': await besedProject,
  'consulty': await consultyProject,
  'sk-acad': await skAcadProject,
  'gazprom': await gazpromProject,
  'gamevalues': await gamevaluesProject,
})