import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx'
import { elementOrParentsScrollable, inRange } from './utils'

export const TRANSITION = 1800

type Slide = {
  el: HTMLElement
  id: string
  halfPage: boolean
}

type SectionHeader = {
  el: HTMLElement
  startIndex: number
  name: string
}

export class Slides {
  @observable
  slides: Slide[] = []
  @observable
  headers: SectionHeader[] = []
  @observable
  container: HTMLElement = null
  @observable
  visited = new Set<number>()
  @observable
  private index = 0
  @observable
  enabled = true

  constructor(container: HTMLElement) {
    makeObservable(this)

    this.container = container

    const slideElements = container.querySelectorAll(':scope > .slide-item, :scope> .section-header')

    let headers: Omit<SectionHeader, 'name'>[] = []
    Array.from(slideElements).forEach((el: HTMLElement) => {
      el.style.setProperty('--i', headers.length.toString())
      if (el.classList.contains('slide-item')) {
        this.slides.push({ el, id: el.id, halfPage: el.classList.contains('project-page') })
      } else {
        headers.push({ el, startIndex: this.slides.length })
      }
    })
    this.headers = headers.map((header) => {
      return {
        ...header,
        name: this.slides[header.startIndex].id,
      }
    })
  }

  @computed
  get visitedSlides() {
    return this.slides.filter((_, i) => this.visited.has(i))
  }

  @computed
  get currentSlideId() {
    return this.slides[this.index].id
  }

  @computed
  get activeSection() {
    return this.headers.find(({ startIndex }) => startIndex >= this.index)
  }

  @computed
  private get leftSlides() {
    return this.slides.slice(0, this.index)
  }

  @computed
  private get rightSlides() {
    return this.slides.slice(this.index + 1)
  }

  @computed
  private get currentSlide() {
    return this.slides[this.index]
  }

  init() {
    return [
      reaction(
        () => this.index,
        (cur, prev) => {

          if (!this.enabled)
            return

          let components = [this.slides[cur], this.slides[prev]]

          let candidatesToAnimate = []
          if (this.slides[cur].halfPage) candidatesToAnimate.push(cur + 1, cur - 1)
          if (this.slides[prev].halfPage) candidatesToAnimate.push(prev + 1, prev - 1)

          const additionalSlides = candidatesToAnimate
            .map((idx) => this.slides[idx] as Slide | undefined)
            .filter((slide) => slide?.halfPage)

          components.push(...additionalSlides)

          components.forEach(({ el }) => {
            el.classList.add('-animated')
            setTimeout(() => el.classList.remove('-animated'), TRANSITION)
          })
        }
      ),
      autorun(() => {
        if (!this.enabled)
          return

        this.leftSlides.forEach((section) => {
          section.el.classList.remove('-active', '-right', '-prepare')
          section.el.classList.add('-left')
        })

        this.rightSlides.forEach((section) => {
          section.el.classList.remove('-active', '-left', '-prepare')
          section.el.classList.add('-right')
        })

        if (this.currentSlide.halfPage) {
          this.leftSlides.slice(-1).forEach((slide) => slide.el.classList.add('-prepare'))
          this.rightSlides.slice(0, 1).forEach((slide) => slide.el.classList.add('-prepare'))
        }

        this.headers.forEach((header) => {
          let classes = [/*to remove*/ '-left', /*to add*/ '-right']
          if (header.startIndex <= this.index) classes = classes.reverse()

          header.el.classList.remove(classes[0])
          header.el.classList.add(classes[1])
        })

        this.visited.add(this.index)

        this.currentSlide.el.classList.remove('-right', '-left')
        this.currentSlide.el.classList.add('-active')
      }),
      autorun(() => {
        this.visitedSlides.forEach((slide) => slide.el.classList.add('-shown'))
      }),
    ]
  }

  @action
  goTo(id: string) {
    const idx = this.slides.findIndex((sect) => sect.id === id)
    if (idx === -1) return
    this.index = idx
  }

  switchToNext() {
    return this.switchToIndex(this.index + 1)
  }

  switchToPrev() {
    return this.switchToIndex(this.index - 1)
  }

  @action
  switchToIndex(index: number) {
    const newIndex = inRange(index, 0, this.slides.length - 1)

    if (newIndex != index && index !== Infinity) return false

    this.index = newIndex
    return true
  }
}
