import { action, makeObservable, observable } from 'mobx'
import { Slides, TRANSITION } from './Slides'
import { elementOrParentsScrollable } from './utils'

export class InputController {
  @observable
  delta = 0

  @observable
  canceled = false

  @observable
  lastUpdate: null | number = null

  slides: Slides

  constructor(slides: Slides) {
    makeObservable(this)

    this.slides = slides

    document.addEventListener('wheel', this.wheelEvent, { passive: true })
    document.addEventListener('keydown', this.keyEvent, { passive: true })
    document.addEventListener('click', this.mouseEvent, { passive: true })
  }

  private get slidesAnimating() {
    return Date.now() < this.lastUpdate + TRANSITION + 10
  }

  @action.bound
  keyEvent(event: KeyboardEvent) {
    const target = event.target as HTMLElement
    if (['INPUT', 'TEXTAREA'].includes(target.tagName)) return

    if (this.slidesAnimating) return

    let switched = false
    switch (event.code) {
      case 'ArrowDown':
      case 'ArrowRight':
      case 'PageDown':
        switched = this.slides.switchToNext()
        break
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'PageUp':
        switched = this.slides.switchToPrev()
        break
      case 'Home':
        switched = this.slides.switchToIndex(0)
        break
      case 'End':
        switched = this.slides.switchToIndex(Infinity)
        break
      case 'Space':
        switched = event.shiftKey ? this.slides.switchToPrev() : this.slides.switchToNext()
        break
    }

    if (switched) this.lastUpdate = Date.now()
  }

  @action.bound
  private mouseEvent(event: MouseEvent) {
    const target = event.target as HTMLElement

    let switched = false

    if ((target.dataset.nav == "prev") || (target.parentElement.dataset.nav == "prev")) 
      switched = this.slides.switchToPrev()

    if ((target.dataset.nav == "next") || (target.parentElement.dataset.nav == "next"))
      switched = this.slides.switchToNext()

    if (switched) this.lastUpdate = Date.now()
  }

  @action.bound
  private wheelEvent(event: WheelEvent) {
    if (this.slidesAnimating) return

    let el: Element = event.target as Element
    if (elementOrParentsScrollable(el)) return

    event.preventDefault()

    const delta = event.deltaX + event.deltaY

    if (delta > 0) this.slides.switchToNext()
    else this.slides.switchToPrev()

    this.lastUpdate = Date.now()

    return false
  }
}
