import { action, makeObservable, observable } from 'mobx'

export class PointerSource {
  x = 0
  y = 0

  constructor() {
    makeObservable(this, {
      x: observable,
      y: observable,
      pointermoveEvent: action,
    })

    document.addEventListener('pointermove', this.pointermoveEvent, { passive: true })
  }

  pointermoveEvent = (event: PointerEvent) => {
    this.x = event.x
    this.y = event.y
  }
}
